import React from "react";
import {useSearchParams} from "react-router-dom";
import Progress from "../../../progress/Progress";

const CreateCombinatorialPlaylistSuccess = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    let distributionProgressId = searchParams.get("distributionProgressId");
    if (distributionProgressId !== null)
        sessionStorage.setItem("latestDistributionProgressId", distributionProgressId);

    let createdPlaylistsString = sessionStorage.getItem(`${distributionProgressId}-combinatorial-created`);
    let createdPlaylists: string[] = createdPlaylistsString != undefined ? JSON.parse(createdPlaylistsString) : [];
    createdPlaylists.sort((a, b) => {
        return a.localeCompare(b, undefined, {numeric: true});
    });
    
    let multiplePlaylists = createdPlaylists.length > 1;
    
    return (
        <div>
            <div className="pageContentSegment">
                <h2>Combination {multiplePlaylists ? "playlists" : "playlist"} created</h2>
                <p>Your combination {multiplePlaylists ? "playlists" : "playlist"} {multiplePlaylists ? "have" : "has"} been created and will be kept up to date.</p>
                <Progress progressId={distributionProgressId}/>
            </div>
            <div className="pageContentSegment">
                <h2>Playlists created</h2>
                <ul>
                    {createdPlaylists.map((createdPlaylist) => <li key={`created-playlist-${createdPlaylist}`}>{createdPlaylist}</li>)}
                </ul>
            </div>
            <div className="pageContentSegment">
                <h2>Want more combination playlists?</h2>
                <p>Go back to <a href="/playlists/combinations">combinaton playlists</a> if you wish to see your created combination playlists or make further changes.</p>
            </div>
            <div className="pageContentSegment">
                <h2>Playlists not appearing in Spotify?</h2>
                <p>
                    This is most likely because you do not have any songs that are applicable for the playlist that you just created. The playlist is created in Spotify
                    when at least one of your songs match the playlists' criteria.
                </p>
            </div>
        </div>
    )
}

export default CreateCombinatorialPlaylistSuccess;