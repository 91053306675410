import './Wizard.css';
import {Playlist} from "../Playlist";
import React, {useState} from "react";
import {CriterionCreator} from "./CriterionCreator";
import {CombinatorialPlaylist} from "./CombinatorialPlaylist";
import {OutlineButton, WarningOutlineButton} from "../../../../common/Buttons";
import {ReactComponent as RemoveIcon} from './trash.svg';

interface WizardProps {
    playlistNumber: number;
    addGenre: (playlistNumber: number, criterionNumber: number, genre: string) => void;
    removeGenre: (playlistNumber: number, criterionNumber: number, genre: string) => void;
    addDecade: (playlistNumber: number, criterionNumber: number, decade: number) => void;
    removeDecade: (playlistNumber: number, criterionNumber: number) => void;
    addMood: (playlistNumber: number, criterionNumber: number, mood: string) => void;
    removeMood: (playlistNumber: number, criterionNumber: number) => void;
    addNationality: (playlistNumber: number, criterionNumber: number, nationality: string) => void;
    removeNationality: (playlistNumber: number, criterionNumber: number) => void;
    setPlaylistName: (playlistNumber: number, playlistName: string) => void;
    availableDecadePlaylists: Playlist[];
    availableGenrePlaylists: Playlist[];
    availableMoodPlaylists: Playlist[];
    availableNationalityPlaylists: Playlist[];
    playlist: CombinatorialPlaylist;
    deletable: boolean;
    removePlaylist: (playlistNumber: number) => void;
}

export const Wizard = (props: WizardProps) => {
    const [activeCriterionCreator, setActiveCriterionCreator] = useState<number>(1);

    let criterionCreators = Array.from(props.playlist.criteria, (criterion) => {
        return (
            <CriterionCreator
                playlistNumber={props.playlistNumber}
                activeCriterionCreator={activeCriterionCreator}
                setAsActiveCriterionCreator={(criterionNumber) => setActiveCriterionCreator(criterionNumber)}
                key={criterion.criterionNumber}
                criterionNumber={criterion.criterionNumber}
                availableGenrePlaylists={props.availableGenrePlaylists}
                availableDecadePlaylists={props.availableDecadePlaylists}
                addGenre={props.addGenre}
                removeGenre={props.removeGenre}
                addDecade={props.addDecade}
                removeDecade={props.removeDecade}
                addMood={props.addMood}
                removeMood={props.removeMood}
                addNationality={props.addNationality}
                removeNationality={props.removeNationality}
                availableNationalityPlaylists={props.availableNationalityPlaylists}
                availableMoodPlaylists={props.availableMoodPlaylists}
                criterion={criterion}
            />
        )
    });

    return (
        <div>
            <div className={"combinationPlaylistCreator"}>
                <div className="combinationPlaylistCreatorHeader">
                    <div className="combinationPlaylistCreatorHeaderText">Songs in this playlist must be</div>
                    <div className={"deleteCombinationPlaylistButton"}>
                        {props.deletable && <WarningOutlineButton text={"Remove playlist"} onClick={() => props.removePlaylist(props.playlistNumber)} icon={RemoveIcon}/>}
                    </div>
                </div>
                {criterionCreators}
                <div className="playlistNamer">
                    <p>What would you like to name the playlist?</p>
                    <div className="userInputContainer">
                        <input placeholder="Playlist name" onChange={(evt) => playlistNameInputChanged(evt)} className="userInput" type="text" value={props.playlist.playlistName}/>
                    </div>
                </div>
            </div>
        </div>
    )

    function playlistNameInputChanged(e: React.FormEvent<HTMLInputElement>) {
        let value: string = e.currentTarget.value;
        if (value.length > 30)
            return;

        if (value.length > 0 && value.match(("^[a-zA-Z0-9 ]+$")) === null)
            return;

        props.setPlaylistName(props.playlistNumber, e.currentTarget.value)
    }
}