import React, {useState} from "react";
import {ApiError, ErrorCode} from "../../../../api/ApiError";
import {ErrorMessage} from "../../../common/ErrorMessage";
import {analyse, analysisResults} from "../../../../api/distributor/DistributeApi";
import {ReactComponent as AnalyseIcon} from './analyse.svg';
import './AnalysisSection.css';
import Progress from "../../progress/Progress";
import {AnalysisResultsResponse, SongsForPreference} from "../../../../api/distributor/AnalysisResponse";
import usePolling from "../../../../util/PollingHook";
import {OutlineButton} from "../../../common/Buttons";

interface AnalysisSectionProps {
    analysisInProgressCallback: (progressId: string) => void;
    analysisFinishedCallback: () => void;
    resultCallback: (result: SongsForPreference[]) => void;
    analysisInProgress: boolean;
}

enum SegmentType {
    Data = "Data",
    Completed = "Completed",
    Cancelled = "Cancelled",
}

export const AnalysisSection = (props: AnalysisSectionProps) => {
    const [errorCode, setErrorCode] = useState<ErrorCode>();
    const [progressId, setProgressId] = useState<string>();
    const [analysisInProgressOfStarting, setAnalysisInProgressOfStarting] = useState<boolean>(false);
    const [cancellationInProgress, setCancellationInProgress] = useState<boolean>(false);
    const [completed, setCompleted] = useState<boolean>(false);
    const [cancelled, setCancelled] = useState<boolean>(false);
    const [afterSegmentNumber, setAfterSegmentNumber] = useState<number>();
    const [numberOfFetchingErrors, setNumberOfFetchingErrors] = useState<number>(0);

    usePolling(async () => {
        if (progressId === null || progressId === undefined) {
            return;
        }
        
        if (numberOfFetchingErrors > 10) {
            return;
        }

        if (completed || cancelled) {
            return;
        }

        return analysisResults(progressId, afterSegmentNumber)
            .then((analysisResults: AnalysisResultsResponse) => {
                if (analysisResults.results.length === 0) {
                    return;
                }

                let results = analysisResults.results;
                results.sort((a, b) => a.segmentNumber - b.segmentNumber);
                
                
                let highestSegmentNumber: number = 0;
                for (let result of results) {
                    if (result.segmentNumber > highestSegmentNumber) {
                        highestSegmentNumber = result.segmentNumber;
                    }

                    props.resultCallback(result.result);

                    if (result.type === SegmentType.Completed) {
                        setCompleted(true);
                        props.analysisFinishedCallback();
                    } else if (result.type === SegmentType.Cancelled) {
                        setAnalysisInProgressOfStarting(false);
                        setCancellationInProgress(false);
                        setCancelled(true);
                        setProgressId(undefined);
                        props.analysisFinishedCallback();
                    }
                }
                setAfterSegmentNumber(highestSegmentNumber);
            }).catch(() => {
                setNumberOfFetchingErrors(numberOfFetchingErrors + 1);
            });
    }, 1500);

    return (
        <div>
            {props.analysisInProgress && <Progress cancellationInProgress={cancellationInProgress} cancelled={cancelled} progressId={progressId}/>}
            <ErrorMessage errorCode={errorCode} smallMarginTop={true}/>
            <div className="analyseSongsSections">
                <OutlineButton text={"Analyse songs per playlist"} onClick={() => startAnalysis()} icon={AnalyseIcon} />
            </div>
        </div>
    )

    function startAnalysis() {
        if (analysisInProgressOfStarting || completed || cancelled || cancellationInProgress) {
            return;
        }

        setAnalysisInProgressOfStarting(true);
        setAfterSegmentNumber(undefined);
        setErrorCode(undefined);

        analyse()
            .then((progressId) => {
                props.analysisInProgressCallback(progressId);
                setProgressId(progressId);
                sessionStorage.setItem("analysisProgressId", progressId)
            })
            .catch((error: ApiError) => {
                setErrorCode(error.errorCode);
                setAnalysisInProgressOfStarting(false);
            });
    }
}