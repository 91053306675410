import {ErrorMessage} from "../../../../common/ErrorMessage";
import {SmallerCancelButton, SmallerConfirmationButton} from "../../../../common/Buttons";
import React, {useState} from "react";
import './CreatePlaylistButtons.css';
import {Criterion, isCriterionEmpty} from "../Criterion";
import {cancelAnalysis, createCombinatorialPlaylist} from "../../../../../api/distributor/DistributeApi";
import {ApiError, ErrorCode} from "../../../../../api/ApiError";
import {CombinatorialPlaylist} from "./CombinatorialPlaylist";
import {Combination} from "../../../../../api/distributor/CreateCombinatorialPlaylistRequest";

interface CreatePlaylistButtonsProps {
    goToOverview: () => void
    combinatorialPlaylists: CombinatorialPlaylist[];
}

export const CreatePlaylistButtons = (props: CreatePlaylistButtonsProps) => {
    const [errorCode, setErrorCode] = useState<ErrorCode>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [createPlaylistsInProgressOfStarting, setCreatePlaylistsInProgressOfStarting] = useState<boolean>(false);

    let createButtonText = props.combinatorialPlaylists.length > 1 ? "Create playlists" : "Create playlist";

    return (
        <>
            <ErrorMessage errorCode={errorCode} errorMessage={errorMessage}/>
            <div className="wizardButtonContainer">
                <SmallerCancelButton onClick={() => props.goToOverview()} text={"Back to overview"}/>
                <ButtonSplitter/>
                <SmallerConfirmationButton onClick={() => createPlaylists()} text={createButtonText}/>
            </div>
        </>
    )

    function createPlaylists(): boolean {
        if (createPlaylistsInProgressOfStarting) {
            return false;
        }

        setErrorCode(undefined);
        setErrorMessage(undefined);
        setCreatePlaylistsInProgressOfStarting(true);

        if (props.combinatorialPlaylists.length === 0) {
            setCreatePlaylistsInProgressOfStarting(false);
            setErrorMessage("Your have not defined any playlist.");
            return false;
        }

        for (let playlist of props.combinatorialPlaylists) {
            if (playlist.playlistName.length === 0) {
                let errorMessage = props.combinatorialPlaylists.length > 1 ? "One or more of your playlists needs a name." : "Your playlist needs a name.";

                setCreatePlaylistsInProgressOfStarting(false);
                setErrorMessage(errorMessage);
                return false;
            }

            let noneEmptyCriterion = playlist.criteria.filter(criterion => !isCriterionEmpty(criterion));
            if (noneEmptyCriterion.length === 0) {
                let errorMessage = props.combinatorialPlaylists.length > 1 ? "You have not added any criteria for one or more of your playlists." : "You have not added any criteria for your playlist.";

                setCreatePlaylistsInProgressOfStarting(false);
                setErrorMessage(errorMessage);
                return false;
            }
        }

        let analysisProgressId = sessionStorage.getItem("analysisProgressId");
        if (analysisProgressId !== null) {
            cancelAnalysis(analysisProgressId)
                .catch((error: ApiError) => setErrorCode(error.errorCode))
                .then(() => doCreateCombinatorialPlaylist())
                .catch(() => setCreatePlaylistsInProgressOfStarting(false));
        } else {
            doCreateCombinatorialPlaylist();
        }

        return false;
    }

    function doCreateCombinatorialPlaylist() {

        let combinations: Combination[] = []
        for (let playlist of props.combinatorialPlaylists) {
            let noneEmptyCriterion = playlist.criteria.filter(criterion => !isCriterionEmpty(criterion));
            combinations.push({
                criteria: noneEmptyCriterion,
                playlistName: playlist.playlistName
            })
        }

        let request = {
            combinations: combinations
        };
        
        let playlistNames = combinations.map(combination => combination.playlistName);

        createCombinatorialPlaylist(request)
            .then((distributionProgressId: string) => {
                sessionStorage.setItem(`${distributionProgressId}-combinatorial-created`, JSON.stringify(playlistNames));
                window.location.replace(`/playlists/combinations/success?distributionProgressId=${distributionProgressId}`)
                return true;
            })
            .catch((error: ApiError) => setErrorCode(error.errorCode))
            .finally(() => setCreatePlaylistsInProgressOfStarting(false));
    }
}

const ButtonSplitter = () => {
    return (
        <div className="buttonSplitter"></div>
    )
}