import React from "react";
import './Groups.css';
import {useAuthorizationContext} from "../../../context/authorizationContext";
import {GetAccessWithPremiumButton} from "./Groups";
import {MembershipLevel, useUserContext} from "../../../context/userContext";
import CombinationExampleDesktop from "../combination_playlists_desktop.png";
import CombinationExampleMobile from "../combination_playlists_mobile.png";

export const CombinationPlaylistExamples = () => {
    const {loggedIn} = useAuthorizationContext();
    const {membershipLevel} = useUserContext();
    const showPremiumButton = loggedIn === false || membershipLevel !== MembershipLevel.PremiumYearly;

    return (
        <div>
            <div className="group pageContentSegment">
                <h1>Combination playlists</h1>
                <p>In a combination playlist you can combine genres, decades, moods, and nationalities to make a playlist that fits your exact needs. This could be:</p>
                <div>
                    <ul>
                        <li>A playlist of all of your rock and rap songs from the 2000s</li>
                        <li>a playlist of all of yours reggae songs from the 1980s and all of your jazz songs.</li>
                        <li>a playlist of all your Brazilian party songs.</li>
                        <li>or a playlist of all your d&b songs from the 1990s.</li>
                    </ul>
                </div>
                <div className="combinationShowcase">
                    <div className={"combinationGraphic desktop"}>
                        <img alt={"Image of a combination playlist being made of two filters: Rap from the 2000s and Rock from the 2000s."}
                             src={CombinationExampleDesktop}/>
                    </div>
                    <div className={"combinationGraphicMobileContainer"}>
                        <div className={"combinationGraphic mobile"}>
                            <img alt={"Image of a combination playlist being made of two filters: Rap from the 2000s and Rock from the 2000s."}
                                 src={CombinationExampleMobile}/>
                        </div>
                    </div>
                </div>
                {showPremiumButton ? <GetAccessWithPremiumButton/> : null}
            </div>
        </div>
    );
}