import {Playlist} from "../Playlist";
import {Wizard} from "./Wizard";
import {CreatePlaylistButtons} from "./CreatePlaylistButtons";
import React, {useState} from "react";
import {Criterion, isCriterionEmpty} from "../Criterion";
import {CombinatorialPlaylist} from "./CombinatorialPlaylist";
import {OutlineButton} from "../../../../common/Buttons";
import {ReactComponent as AddIcon} from './plus.svg';
import './WizardContainer.css';
import {MaximumNumberOfCombinatorialPlaylists} from "../CreateCombinatorialPlaylist";
import {ErrorMessage} from "../../../../common/ErrorMessage";

interface WizardsContainerProps {
    numberOfCombinatorialPlaylists: number;
    availableDecadePlaylists: Playlist[];
    availableGenrePlaylists: Playlist[];
    availableNationalityPlaylists: Playlist[];
    availableMoodPlaylists: Playlist[];
    goToOverview: () => void;
}

const MaximumCriteria: number = 20;

export const WizardsContainer = (props: WizardsContainerProps) => {
    const [playlists, setPlaylists] = useState<CombinatorialPlaylist[]>([
        {
            criteria: [
                {
                    criterionNumber: 1,
                    genres: [],
                    decade: undefined,
                    mood: undefined,
                    nationality: undefined,
                }
            ],
            playlistName: ""
        }
    ])
    const [errorMessage, setErrorMessage] = useState<string>();

    let wizards = playlists.map((playlist, playlistNumber) =>
        <Wizard
            key={playlistNumber}
            playlistNumber={playlistNumber}
            playlist={playlist}
            availableDecadePlaylists={props.availableDecadePlaylists}
            availableGenrePlaylists={props.availableGenrePlaylists}
            availableNationalityPlaylists={props.availableNationalityPlaylists}
            availableMoodPlaylists={props.availableMoodPlaylists}
            addGenre={addGenre}
            removeGenre={removeGenre}
            addDecade={addDecade}
            removeDecade={removeDecade}
            addMood={addMood}
            removeMood={removeMood}
            addNationality={addNationality}
            removeNationality={removeNationality}
            setPlaylistName={setPlaylistName}
            deletable={playlistNumber > 0}
            removePlaylist={removePlaylist}/>);

    return (
        <div>
            <div className={"addPlaylistButton"}>
                <ErrorMessage smallMarginTop={true} errorMessage={errorMessage}/>
                <OutlineButton text={"Add playlist"} onClick={() => addPlaylist()} icon={AddIcon}/>
            </div>
            {wizards}
            <CreatePlaylistButtons goToOverview={props.goToOverview} combinatorialPlaylists={playlists}/>
        </div>
    )

    function addPlaylist() {
        let creatableNumberOfPlaylists = MaximumNumberOfCombinatorialPlaylists - props.numberOfCombinatorialPlaylists - playlists.length;
        if (creatableNumberOfPlaylists == 0) {
            setErrorMessage("You have no more room for playlists");
            return;
        }

        setErrorMessage(undefined);
        setPlaylists((prevState) => {
            let newPlaylist = {
                criteria: [
                    {
                        criterionNumber: 1,
                        genres: [],
                        decade: undefined,
                        mood: undefined,
                        nationality: undefined,
                    }
                ],
                playlistName: ""
            };

            return [...prevState, newPlaylist];
        });
    }

    function removePlaylist(playlistNumber: number) {
        setPlaylists((prevState) => prevState.filter((playlist, index) => index !== playlistNumber));
    }

    function addGenre(playlistNumber: number, criterionNumber: number, genre: string) {
        setPlaylists((prevPlaylists) =>
            prevPlaylists.map((playlist, idx) => {
                if (idx !== playlistNumber) return playlist;

                const updatedCriteria = playlist.criteria.map((criterion) =>
                    criterion.criterionNumber === criterionNumber
                        ? {...criterion, genres: [...criterion.genres, genre]}
                        : criterion
                );

                return {
                    ...playlist,
                    criteria: updatedCriteria,
                };
            })
        );

        initializeNewCriterionIfNeeded(playlistNumber, criterionNumber);
    }

    function removeGenre(playlistNumber: number, criterionNumber: number, genre: string) {
        setPlaylists((prevPlaylists) =>
            prevPlaylists.map((playlist, idx) => {
                if (idx !== playlistNumber) {
                    return playlist;
                }

                const updatedCriteria = removeEmptyCriterion(playlist.criteria
                    .map((criterion) =>
                        criterion.criterionNumber === criterionNumber
                            ? {...criterion, genres: criterion.genres.filter((oldGenre) => oldGenre !== genre)}
                            : criterion
                    )
                );

                return {
                    ...playlist,
                    criteria: updatedCriteria,
                };
            })
        );
    }

    function addDecade(playlistNumber: number, criterionNumber: number, decade: number) {
        setPlaylists((prevPlaylists) =>
            prevPlaylists.map((playlist, idx) => {
                if (idx !== playlistNumber) return playlist;

                const updatedCriteria = playlist.criteria.map((criterion) =>
                    criterion.criterionNumber === criterionNumber
                        ? {...criterion, decade}
                        : criterion
                );

                return {
                    ...playlist,
                    criteria: updatedCriteria,
                };
            })
        );

        initializeNewCriterionIfNeeded(playlistNumber, criterionNumber);
    }

    function removeDecade(playlistNumber: number, criterionNumber: number) {
        setPlaylists((prevPlaylists) =>
            prevPlaylists.map((playlist, idx) => {
                if (idx !== playlistNumber) {
                    return playlist;
                }

                const updatedCriteria = removeEmptyCriterion(playlist.criteria
                    .map((criterion) =>
                        criterion.criterionNumber === criterionNumber
                            ? {...criterion, decade: undefined}
                            : criterion
                    )
                );

                return {
                    ...playlist,
                    criteria: updatedCriteria,
                };
            })
        );
    }

    function addMood(playlistNumber: number, criterionNumber: number, mood: string) {
        setPlaylists((prevPlaylists) =>
            prevPlaylists.map((playlist, idx) => {
                if (idx !== playlistNumber) return playlist;

                const updatedCriteria = playlist.criteria.map((criterion) =>
                    criterion.criterionNumber === criterionNumber
                        ? {...criterion, mood}
                        : criterion
                );

                return {
                    ...playlist,
                    criteria: updatedCriteria,
                };
            })
        );

        initializeNewCriterionIfNeeded(playlistNumber, criterionNumber);
    }

    function removeMood(playlistNumber: number, criterionNumber: number) {
        setPlaylists((prevPlaylists) =>
            prevPlaylists.map((playlist, idx) => {
                if (idx !== playlistNumber) {
                    return playlist;
                }

                const updatedCriteria = removeEmptyCriterion(playlist.criteria
                    .map((criterion) =>
                        criterion.criterionNumber === criterionNumber
                            ? {...criterion, mood: undefined}
                            : criterion
                    )
                );

                return {
                    ...playlist,
                    criteria: updatedCriteria,
                };
            })
        );
    }

    function addNationality(playlistNumber: number, criterionNumber: number, nationality: string) {
        setPlaylists((prevPlaylists) =>
            prevPlaylists.map((playlist, idx) => {
                if (idx !== playlistNumber) return playlist;

                const updatedCriteria = playlist.criteria.map((criterion) =>
                    criterion.criterionNumber === criterionNumber
                        ? {...criterion, nationality}
                        : criterion
                );

                return {
                    ...playlist,
                    criteria: updatedCriteria,
                };
            })
        );

        initializeNewCriterionIfNeeded(playlistNumber, criterionNumber);
    }

    function removeNationality(playlistNumber: number, criterionNumber: number) {
        setPlaylists((prevPlaylists) =>
            prevPlaylists.map((playlist, idx) => {
                if (idx !== playlistNumber) {
                    return playlist;
                }

                const updatedCriteria = removeEmptyCriterion(playlist.criteria
                    .map((criterion) =>
                        criterion.criterionNumber === criterionNumber
                            ? {...criterion, nationality: undefined}
                            : criterion
                    )
                );

                return {
                    ...playlist,
                    criteria: updatedCriteria,
                };
            })
        );
    }

    function initializeNewCriterionIfNeeded(playlistNumber: number, criterionNumber: number) {
        setPlaylists((prevPlaylists) =>
            prevPlaylists.map((playlist, idx) => {
                if (idx !== playlistNumber) {
                    return playlist;
                }

                if (!(criterionNumber >= playlist.criteria.length && playlist.criteria.length < MaximumCriteria)) {
                    return playlist
                }

                let newCriterion = {
                    criterionNumber: criterionNumber + 1,
                    decade: undefined,
                    genres: []
                };

                return {
                    ...playlist,
                    criteria: [...playlist.criteria, newCriterion],
                };
            })
        );
    }

    function removeEmptyCriterion(criteria: Criterion[]): Criterion[] {
        let newCriteria = criteria.filter(criterion => {
            let isFirstCriterion = criterion.criterionNumber === 1;
            if (isFirstCriterion && criteria.length === 1) {
                return true;
            }

            let isLastCriterion = criterion.criterionNumber === criteria[criteria.length - 1].criterionNumber;
            if (isLastCriterion) {
                return true;
            }

            return !isCriterionEmpty(criterion);
        });

        newCriteria = newCriteria.map((criterion, idx) => {
            return {
                ...criterion,
                criterionNumber: idx + 1,
            };
        });

        return newCriteria;
    }

    function setPlaylistName(playlistNumber: number, playlistName: string) {
        setPlaylists((prevPlaylists) =>
            prevPlaylists.map((playlist, idx) => {
                if (idx !== playlistNumber) {
                    return playlist;
                }

                return {
                    ...playlist,
                    playlistName
                }
            })
        );
    }
}